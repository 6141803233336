<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">How did we do?</h3>

    <Expectations v-model="form.expectations_met"/>
    <AdvisorRated v-model="form.advisor_rated"/>
    <ImproveYN v-model="form.improve"/>
    <div v-if="form.improve"  class="question-box with-arrow">
      <ImproveDetails  v-model="form.improve_details"/>
    </div>

    <Comment v-model="form.comment" />

    <ReferralFull
        :transaction-type="form.transaction_type"
    />

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import {statusOptions} from "../../question/options/statusOptions";

import Expectations from "../../question/questions/feedback/Expectations";
import AdvisorRated from "../../question/questions/feedback/AdvisorRated";

import ReferralFull from "@/components/common/questionnaires/fieldsets/referral/ReferralFull";
import Comment from "@/components/common/questionnaires/question/questions/feedback/Comment";
import ImproveYN from "@/components/common/questionnaires/question/questions/feedback/ImproveYN";
import ImproveDetails from "@/components/common/questionnaires/question/questions/feedback/ImproveDetails";

export default {
  name: 'FeedbackFull',
  mixins: [fieldsetHelpers, statusOptions],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    ImproveDetails,
    ImproveYN,
    Comment,
    Expectations,
    AdvisorRated,
    ReferralFull,
  },
  data() {
    return {
      label: 'Feedback', // used for steps progress
      referrals: [],
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>

<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="!!data">
    <InputRadio
        ref="input"
        v-model="data"
        :options="options"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        class="radio-options-wide"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";

import QuestionBase from "../../QuestionBase";
import InputRadio from "../../inputs/InputRadio";

export default {
  name: 'InterestType',
  mixins: [questionLoadHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    transactionType: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.setOptions()
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'What service is this person interested in?',
        subTitle: null,
        tip: null,
      },
      db: {
        saveLocation: 'referral_save',
        saveField: 'name',
        objectId: this.objectId
      },
      args: {
        skippable: false,
        label: null,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
      options: []
    }
  },
  methods: {
    setOptions () {
      this.options = [
        {
          label: 'Estate Planning',
          value: 'estate_planning'
        },
        {
          label: 'Insurance',
          value: 'insurance'
        },
        {
          label: 'Mortgage Advice',
          value: 'mortgage'
        }
      ]
      if (this.transactionType === 'estate_planning_free') {
        this.options.push(
            {
              label: 'Free Will Writing',
              value: 'estate_planning_free'
            },
        )
      }
    }
  }
}
</script>

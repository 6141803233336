export const feedbackOptions = {
    data() {
        return {
            expectationsOptions: [
                {
                    label: 'Exceeded',
                    value: 'exceeded'
                },
                {
                    label: 'Did Not Meet',
                    value: 'not_met'
                },
                {
                    label: 'Met',
                    value: 'met'
                }
            ],
            advisorRatedOptions: [
                {
                    label: 'Great',
                    value: 'great'
                },
                {
                    label: 'Poor',
                    value: 'poor'
                },
                {
                    label: 'Good',
                    value: 'good'
                }
            ],
        }
    }
}

<template>
  <div>
    <h5 class="fieldset-heading">Refer a Friend</h5>

    <QuestionBase :question="question" :valid="false" :errors="[]">

    </QuestionBase>
    <div class="question-box with-arrow">
      <Referrals
          v-model="referrals"
          ref="referrals"
          :transactionType="transactionType"
          @refresh="refreshReferrals"
      />

    </div>
  </div>

</template>

<script>

import Referrals from "@/components/common/questionnaires/question/questions/referral/Referrals";
import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";

export default {
  name: 'ReferralFull',
  mounted() {
    this.refreshReferrals();
  },
  props: {
    transactionType: {
      type: String,
      required: true
    }
  },
  components: {
    QuestionBase,
    Referrals,
  },
  data() {
    return {
      label: 'Referrals', // used for steps progress
      referrals: []
    }
  },
  computed: {
    formatted() {
      if (this.transactionType === 'estate_planning_free') return "Do you know anybody that might be interested in making a Will?";
      if (this.transactionType === 'estate_planning') return "Do you know anybody that might be interested in making a Will or reviewing their estate planning arrangements?";
      if (this.transactionType === 'mortgage') return "Do you know anybody that might be interested in reviewing their mortgage or buying a property?";
      if (this.transactionType === 'insurance') return "Do you know anybody that might be interested in reviewing their insurance arrangements?";
      return 'advice';
    },
    others () {
      if (this.transactionType === 'estate_planning_free') return "The people that you add will be entitled to 50% off our will writing service.";
      if (this.transactionType === 'estate_planning') return "The people that you add will be entitled to a discount on the estate planning services that we provide including 50% of will writing.";
      if (this.transactionType === 'mortgage') return "If any of the people referred end up taking a mortgage you will receive a £50 Amazon voucher.";
      if (this.transactionType === 'insurance') return "Remember the service is entirely free of charge for friends and family.";
      return '';
    },
    question () {
      return {
        title: `${this.formatted}`,
        subTitle: `When you add people they will sent a link allowing them to book an appointment if they are interested. ${this.others}`
      }
    }
  },
  methods: {
    refreshReferrals() {
      this.$refs.referrals.get()
    }
  }
};
</script>

<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :args="args"
        :server-errors="serverErrors"
        :options="booleanYesNo"
        :no-save="false"
        :db="db"
        @jsErrors="jsErrors=$event"
        ref="input"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../helpers/questionLoadHelpers";
import { general } from "../../options/general";

import QuestionBase from "../../QuestionBase";
import InputRadio from "../../inputs/InputRadio";

export default {
  name: 'ImproveYN',
  mixins: [general, questionLoadHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'Could the service be improved?',
        subTitle:  null,
        tip: null,
      },
      db: {
        saveLocation: 'feedback_save',
        saveField: 'improve'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>

<template>
  <QuestionBase :id="db.saveField"
                :errors="[...jsErrors]"
                :question="question"

                :valid="!!data.length">
    <transition name="fade">
      <div class="accordion sub-items">

        <ObjectCard
            v-for="(referral, index) in data"
            :id="'referral'+index"
            :key="'referral' + referral.id"
            :ref="'referral'+index"
            v-model="data[index]"
            :title="`${referral.name}`"
            type="referral"
            :loading="loading"
            :showDelete="false"
            :showSave="false"
            :showConfirm="false"
            @delete="deleteObject(referral.id)"
            @save="saveObject(referral, 'referral'+index)">
          <b-row class="m-24">
            <b-col v-if="data[index].email">
              <h4><i class="i-Email"></i> {{ data[index].email }}</h4>
            </b-col>
            <b-col v-if="data[index].phone_number">
              <h4><i class="i-Telephone"></i> {{ data[index].phone_number }}</h4>
            </b-col>
          </b-row>
        </ObjectCard>

        <ObjectCard
            v-if="form"
            ref="referral-add"
            v-model="form"
            title="New Referral"
            type="referral-add"
            :loading="loading"
            @delete="form=null"
            @save="saveObject(form, db.saveField)">
          <Component
              :is="'Referral'"
              v-model="form"
              :errors-post="errorsPost"
              :transactionType="transactionType"
              :noSave="true"/>
        </ObjectCard>

        <b-button
            v-if="!form"
            class="btn-question w-100"
            @click="add">
          <i class="i-Home-2 text-25 font-weight-800 mr-2"></i>
          Add Referral
        </b-button>
      </div>
    </transition>
  </QuestionBase>
</template>
<script>
import {objectSaveHelpers} from "../objects/objectSaveHelpers";
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";
import {blankForms} from "../../helpers/blankForms";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import ObjectCard from "@/components/common/questionnaires/question/questions/objects/ObjectCard";
import Referral from "@/components/common/questionnaires/fieldsets/sub/referral/Referral";

export default {
  name: 'Referrals',
  components: {
    Referral,
    ObjectCard,
    QuestionBase,
  },
  mixins: [
    objectSaveHelpers,
    questionLoadHelpers,
    blankForms
  ],
  props: {
    value: {
      type: Array,
      required: false
    },
    type: {
      type: String,
      default: 'full'
    },
    transactionType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      question: {
        title: null,
        subTitle: null,
        tip: null,
      },
      db: {
        saveLocation: 'referral_save',
        saveField: null,
        deleteLocation: 'referrals',
        loadLocation: 'referrals',
        syncMultipleObjectOnSave: true,
        fetchObjectsOnSave: false
      },
      args: {
        skippable: true,
        required: false,
        customErrorMessageNewOpenForm: "Save before proceeding, by clicking 'confirm'"
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      form: null,
      errors: {}
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        if (this.value) {
          return this.value;
        }
        return null;
      }
    },
  },
  methods: {
    add() {
      this.form = null
      this.form = Object.assign({}, this.referralBlankForm)
      this.form.interest_type = this.transactionType;
    }
  }
}
</script>

<template>
  <div>

    <InterestType
        v-model="form.interest_type"
        :transactionType="transactionType"
        :errors-post="errorsPost.name"
        :object-id="objectId"
        :no-save="noSave"/>

    <Name
        v-model="form.name"
        :form="form"
        :errors-post="errorsPost.name"
        :object-id="objectId"
        :no-save="noSave"
    />

    <ContactInformation
        :value-first="form.email"
        :value-second="form.phone_number"
        @inputFirst="form.email=$event"
        @inputSecond="form.phone_number=$event"
        :object-id="objectId"
        :no-save="noSave"
        :errors-post-phone-number="errorsPost.phone_number"
        :errors-post-email="errorsPost.email"
    />
  </div>
</template>

<script>
import Name from "@/components/common/questionnaires/question/questions/referral/Name";
import ContactInformation from "@/components/common/questionnaires/question/questions/referral/ContactInformation";
import InterestType from "@/components/common/questionnaires/question/questions/referral/InterestType";

export default {
  name: 'Referral',
  components: {
    InterestType,
    Name,
    ContactInformation,
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    },
    transactionType: {
      type: String,
      required: true
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>

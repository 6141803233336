<template>
  <QuestionnaireBase :title="title" :form="form" :finishFunction="null">
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../loadHelpers";
import {httpQuestionnaire} from "@/services";
import QuestionnaireBase from "../../../components/common/questionnaires/questionnaire";
import FeedbackFull from "../../../components/common/questionnaires/fieldsets/feedback/FeedbackFull";

export default {
  name: "Feedback",
  mixins: [loadHelpers],
  components: {
    QuestionnaireBase,
    FeedbackFull,
  },
  data() {
    return {
      name: 'Feedback',
      title: "Feedback",
      url: 'feedback',
      order: [ // imported components required here to load
        'FeedbackFull',
      ],
      expiryAccessCodeOnFinish: true,
      finishFunction: this.complete
    };
  },
  methods: {
    complete() {
      let data = {
        completed_date: new Date()
      }
      httpQuestionnaire.patch('feedback_save', data).catch(error => {
        console.log(error)
      })
    }
  }
};
</script>
